import Img from "gatsby-image"
import React from "react"

/** @jsx jsx */
import { jsx } from "theme-ui"

const SplitTile = ({ children, inverted, image, imageInfo }) => {
  const main = (
    <main key="image">
      <div
        style={{
          maxWidth: `512px`,
          minWidth: "200px",
          marginBottom: `1.45rem`,
        }}
      >
        <Img
          style={{
            transform: "rotate(3deg)",
            boxShadow: "0 0 10px 2px rgba(102, 102, 102, 0.2)",
          }}
          fluid={image}
          title={imageInfo}
          alt={imageInfo}
        />
      </div>
    </main>
  )

  const aside = (
    <aside key="text">
      <div
        sx={{
          maxWidth: 512,
          mx: "auto",
          px: 3,
          pt: [0, 4],
          pb: 4,
        }}
      >
        {children}
      </div>
    </aside>
  )

  return (
    <div
      sx={{
        display: "grid",
        gridGap: 4,
        alignItems: "center",
        gridTemplateColumns: inverted
          ? ["auto", "350px 1fr"]
          : ["auto", "1fr 350px"],
      }}
    >
      {inverted ? [aside, main] : [main, aside]}
    </div>
  )
}

export default SplitTile
